<!-- Settings -->
<template>
    <div  v-loading="loading">
    <div class="card card-custom">
      <div class="card-body p-0">        
          <div class="row">           
            <div class="col-sm-6 col-xs-12">
              <b-form-group>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                 <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-sm-6 col-xs-12 text-right">
              <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="addReturn"><i class="fa fa-plus"></i>Add Return Product
              </b-button>
               
            </div>
            
          </div>
        </div>
       
          <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column label="Return ID" sortable width="120" fixed="" align="center">
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewReturnorder(scope.row.return_order.id)">RT-{{scope.row.id}}</a></el-tag>                
                </template>
              </el-table-column>           
              <el-table-column label="Order #" sortable width="120" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.return_order.order.id)">{{(scope.row.return_order.order.order_number) ? scope.row.return_order.order.order_number : ""}}</a></el-tag>                
                  
                </template>
              </el-table-column> 
              <el-table-column label="Return Date"  sortable prop="created_date"  width="120" >
                <template slot-scope="scope">
                {{scope.row.created_date|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column>                
              <!-- <el-table-column label="Customer Name" prop="customer_name" sortable width="200" >
                <template slot-scope="scope">
                {{scope.row.return_order.customer?scope.row.return_order.customer.first_name+' '+(scope.row.return_order.customer.last_name == null?"":scope.row.return_order.customer.last_name):""}}
                </template>
              </el-table-column> -->
              <el-table-column label="SKU" prop="sku" sortable width="110" >
                <template slot-scope="scope">
                {{scope.row.product?scope.row.product.sku:''}}
                </template>
              </el-table-column>
              <el-table-column label="Product Name" prop="name" sortable width="200" >
                <template slot-scope="scope">
                {{scope.row.product?scope.row.product.name:''}}
                </template>
              </el-table-column>
                <el-table-column label="Return Reason" prop="return_reason"  width="200" >
                <template slot-scope="scope">
                {{scope.row.return_order.return_reason?scope.row.return_order.return_reason:''}}
                </template>
              </el-table-column>
              <el-table-column label="Return Payment" prop="return_reason"  width="150" >
                <template slot-scope="scope">
                  <span v-if="scope.row.return_order.return_amount_type == 'credit_memo'">credit memo</span>
                  <span v-else>{{ scope.row.return_order.return_amount_type }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Return Qty" prop="ret_sub_total"  width="100" align="center" >
                <template slot-scope="scope">
                {{scope.row.return_quantity}} 
                </template>
              </el-table-column> 
              <el-table-column label="Price" prop="ret_tax_total"  width="100" align="center" >
                <template slot-scope="scope">
                ${{parseFloat(scope.row.unit_price).toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column label="Total" prop="discount"  width="100" align="center" >
                <template slot-scope="scope">
                ${{parseFloat(scope.row.ret_total).toFixed(2)}} 
                </template>
              </el-table-column>  
                <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>  
                      <el-dropdown-item :command="3" ><i class="fa fa-envelope"></i>Send Email</el-dropdown-item>
                       <el-dropdown-item :command="4" ><i class="fa fa-download"></i>Download</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>      
      </div>         
<v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllReturnproducts,downloadPdf} from "@/api/returnproduct";
import {getAllActiveSites} from "@/api/website";
import CreateAddress from '@/components/CreateAddress/add.vue';
import AddCredit from '@/components/CreateCustomer/add_credit.vue';
import SendEmail from '@/components/SendEmail'
  export default {
    name: "return-product",
    data() {
      return {
        loading:false,
        list: [],
        websites:[],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },  
        filter: null,
        selectedWebsite: null,
        view_type:'return_order',
    
        checkAll:false,
        isIndeterminate: true,
      
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    mounted() {
       this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Return Product" },
        
      ]);
    },
    created(){
      this.fetchData(1);
    },
    methods:{
     
      // updateStatus(customer_id,status){
      //   changeStatus(customer_id,status).then(response => {
      //     this.$showResponse('success', response.data.message);
      //   })
      // },
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
     
      handleCheckedCitiesChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.column_list.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.column_list.length;
      },
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
         if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        getAllReturnproducts(params).then(response => {
          this.list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })

        getAllActiveSites(params).then(response => {
          this.websites = response.data.data
          this.loading = false
        })
      },
      viewOrder(order_id){
         this.$router.push({path:'/order/view/'+order_id})
      },
      viewReturnorder(return_id) {
      this.$router.push({ path: "/return/order/view/" + return_id });
    },
      getWebsitevalues(){
        this.fetchData(1)
      },
      addReturn(){
        this.$router.push({path:'/return/add'})
      },
      viewReturn(id){
         this.$router.push({path:'/return/view/'+id})
      },
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({ path: "/return/order/view/" + row.return_order.id });
          }                  
          else if (evt === 3){ 
                let customer = row.return_order.customer;
                let order_id = row.return_order.id;
                let type = this.view_type;

            this.$modal.show(SendEmail, {customer, type, order_id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          } else if (evt === 4){            
            this.loading=true;
            downloadPdf(row.return_order.id , this.view_type).then(response => {
          //console.log(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Return Order.pdf');
                document.body.appendChild(link);
                link.click();
                this.loading = false;
              }).catch(err => {
                console.log(err);
                this.loading = false;
              });
          }
        
         
     
    },
      CreateCredit(row){
        this.$modal.show(AddCredit, {
          customer_id: row.id
        }, 
        {draggable: true, transition: 'pop-out', overflow:'auto', height: 'auto', clickToClose: false, adaptive: true, width: '600px' })
      },
      viewCustomer(customer_id){
        this.$router.push({path:'/customer/view/'+customer_id});
      },
      createAddress(row){
        this.$modal.show(CreateAddress, {
            customer_id: row.id
          }, 
          { draggable: true, transition: 'pop-out', overflow:'auto', height: 'auto', clickToClose: false, adaptive: true, width: '600px' })
      },
    }
  }
</script>